import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const StickySocial = () => {
  return (
    <div class="sticky-icon">
            <a href="https://www.linkedin.com/company/n-v-concrete/" class="LinkedIn"><FontAwesomeIcon icon={faLinkedin} size="2x" /> LinkedIn </a>
            <a href="https://www.facebook.com/n.v.concrete11?mibextid=ZbWKwL" class="Facebook"><FontAwesomeIcon icon={faFacebook} size="2x" /> Facebook </a>
            <a href="https://instagram.com/n.v.concrete?igshid=ZDc4ODBmNjlmNQ==" class="Instagram"><FontAwesomeIcon icon={faInstagram} size="2x" /> Instagram </a>
    </div>
  )
}
