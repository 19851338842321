import { Container, Row, Col } from "react-bootstrap";
import {jsx as _jsx} from 'react/jsx-runtime';
import logo from "../assets/img/logo.jpeg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faPhone, faSquareEnvelope } from '@fortawesome/free-solid-svg-icons'

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center p-3 txt-black">
          <Col size={12} sm={6}>
            <h5><FontAwesomeIcon icon={faAddressCard} /> Head Office </h5> 
            <p >NV Concrete, Sr.No. 225/2, Bandal Estate,Uttamnagar, next to Puranik Abhitante, Bavdhan, Pune, Maharashtra 411021</p>
            <p> <FontAwesomeIcon icon={faPhone} />  +91 93709 04315<br></br>
                <FontAwesomeIcon icon={faPhone} /> +91 93709 04315
            </p>
            <p><FontAwesomeIcon icon={faSquareEnvelope} /> vaibhav.patil@nvconcrete.in</p>
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
              <div className="">
                  <img src={logo} alt="Header Img"/>
                </div>
                {/* <div className="responsive">
                  <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.085229079356!2d73.75481097519179!3d18.525050282569325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bf8fc566258d%3A0xbfedc36e2c1d49f3!2sNV%20Concrete!5e0!3m2!1sen!2sin!4v1692515635709!5m2!1sen!2sin" 
                    style={{ border: "0" }}
                    width="600"
                    height="300"
                    allowfullscreen="" 
                    loading="lazy" 
                    referrerpolicy="no-referrer-when-downgrade">
                  </iframe>
                </div> */}
          </Col>
        </Row>
      </Container>
      <div className="text-center txt-black">
        <hr></hr>
        <h6>&copy; 2023 NVConcerete Pvt Ltd. All Rights Reserved</h6>
      </div>
    </footer>
  )
}
