import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import contactImg from "../assets/img/contact-img.svg";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Quality = () => {
  const formInitialDetails = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  }
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Send');
  const [status, setStatus] = useState({});

  return (
    <section className="quality" id="quality">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""} src={contactImg} alt="Contact Us">
                  <h6>QUALITY HAS PROVEN</h6>
                  <h1>
                    <p className="quality_control">
                    OUR CREDIBILITY
                    HAS BEEN PROVEN
                    </p>
                  </h1>
                </div>
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <div className="quality_control">
                  <p>
                  We consider specialized capability as our core faculty.
                  masterminds with applicable experience, exposure and educational qualifications are leading the specialized department.
                  NV Technical Team is committed to R&D in concrete technology. Services of famed concrete technologists are profited regularly for better knowledge creation.
                  </p>
                  <p>
                  NV Concrete manufactures in its completely automated and computerized concrete batching shops and inventories concrete composites,
                  which are designed in its well- equipped laboratories as per established procedures to misbehave with colorful vittles of Indian norms.
                  NV Concrete's educated logistics platoon works nearly with you to insure that the concrete of needful quality is delivered on time and in the applicable amounts.
                  </p>
                </div>
                <Container className="quality_control">
                <Row>
                  <Col><FontAwesomeIcon icon={faSquareCheck}/> Material Quality Control </Col>
                  <Col><FontAwesomeIcon icon={faSquareCheck}/> 100% satisfaction guarantee</Col>
                </Row>
                <Row>
                  <Col><FontAwesomeIcon icon={faSquareCheck}/> Highly Professional Staff </Col>
                  <Col><FontAwesomeIcon icon={faSquareCheck}/> High Tech Plant</Col>
                </Row>
                <Row>
                  <Col><FontAwesomeIcon icon={faSquareCheck}/> Production capacity of 30 cu.m per hour. </Col>
                  <Col><FontAwesomeIcon icon={faSquareCheck}/> 8 transit mixtures with modern Concrete Mixers</Col>
                </Row>
                </Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
