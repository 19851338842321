import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/work_img1.png";
import projImg2 from "../assets/img/work_img2.png";
import projImg3 from "../assets/img/work_img3.png";
import projImg4 from "../assets/img/work_img4.png";
import projImg5 from "../assets/img/dustFilter.webp";
import colorSharp2 from "../assets/img/color-sharp2.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCheck, faUserGear } from '@fortawesome/free-solid-svg-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "High Tech Equipments",
      description: "Modern high tech equipement ready deliver best quality.",
      imgUrl: projImg2,
    },
    {
      title: "Transportation",
      description: "Delivering the ready-mix concrete in the ideal condition to the project ",
      imgUrl: projImg1,
    },
    {
      title: "Quality Assurance",
      description: "We provide the best quality of ready-mix cement.",
      imgUrl: projImg4,
    },
  ];

  return (
    <section className="project" id="plant">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Plant Features</h2>
                <p>Ready mixed concrete is an advanced technology, involving a high degree of robotization and robotization. A typical RMC factory consists of silos and lockers for the storehouse of cement and summations independently, weigh batchers for proportioning different constituents of concrete, high effectiveness mixer for thorough mixing of constituents, and a motorized system controlling the entire product process.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Features</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Milestones </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Equipments</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <div className="d-flex align-items-center justify-content-center">
                      <Container className="equipment">
                      <TrackVisibility>
                          {({ isVisible }) =>
                           <Row className={isVisible ? "animate__animated animate__backInLeft mileStone" : "mileStone"}>
                           <Col><FontAwesomeIcon icon={faSquareCheck}/> HIGHEST PRODUCTION 5021 cu.m ACHIEVED IN DEC 20 </Col>
                         </Row>}
                        </TrackVisibility>
                        <TrackVisibility>
                          {({ isVisible }) =>
                            <Row className={isVisible ? "animate__animated animate__backInRight mileStone" : "mileStone"}>
                            <Col><FontAwesomeIcon icon={faSquareCheck}/> HIGHEST PRODUCTION 491 cu.m PER DAY FOR TEJAS REALITY WAKAD. </Col>
                          </Row>}
                        </TrackVisibility>
                        <TrackVisibility>
                          {({ isVisible }) =>
                            <Row className={isVisible ? "animate__animated animate__backInLeft mileStone" : "mileStone"}>
                            <Col><FontAwesomeIcon icon={faSquareCheck}/> WE SUPPLY 274 cu.m IN 12 HRS FOR PURANIKS BUILDERS IN BAVDHAN. </Col>
                          </Row>}
                        </TrackVisibility>
                        <TrackVisibility>
                          {({ isVisible }) =>
                            <Row className={isVisible ? "animate__animated animate__backInRight mileStone" : "mileStone"}>
                            <Col><FontAwesomeIcon icon={faSquareCheck}/> SUPPLYING CONCRETE GRADE OF M7.5 TO M50. </Col>
                          </Row>}
                        </TrackVisibility>      
                        
                        
                        
                        
                      </Container>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                    <Container className="equipment">                  
                        <Row>
                          <Col><FontAwesomeIcon icon={faUserGear}/> Schwing Stetter CP 30 Plant </Col>
                        </Row>
                        <Row>
                          <Col><FontAwesomeIcon icon={faUserGear}/> 250 kva DG </Col>
                        </Row>
                        <Row>
                          <Col><FontAwesomeIcon icon={faUserGear}/> 3 nos silos </Col>
                        </Row>
                        <Row>
                          <Col><FontAwesomeIcon icon={faUserGear}/> 7 cu.m 6 nos Transit mixture </Col>
                        </Row>
                        <Row>
                          <Col><FontAwesomeIcon icon={faUserGear}/> 10 cu.m 2 nos Transit mixture </Col>
                        </Row>
                        <Row>
                          <Col><FontAwesomeIcon icon={faUserGear}/> 2 nos Aquarius concrete pump </Col>
                        </Row>
                        <Row>
                          <Col><FontAwesomeIcon icon={faUserGear}/> 2 nos Toeing Van </Col>
                        </Row>
                        <Row>
                          <Col><FontAwesomeIcon icon={faUserGear}/> Backhoe Loader </Col>
                        </Row>
                        <Row>
                          <Col><FontAwesomeIcon icon={faUserGear}/> Fully equipped quality lab </Col>
                        </Row>
                      </Container>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
