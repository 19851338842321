import meter1 from "../assets/img/clients_logo/Anshul realty.jpg";
import meter2 from "../assets/img/clients_logo/Ashwini builders.png";
import meter3 from "../assets/img/clients_logo/Avanta.png";
import meter4 from "../assets/img/clients_logo/Avior-Logo1.png";
import meter5 from "../assets/img/clients_logo/Official_logo_of_the_Pune_Municpal_Corporation.jpeg";
import meter6 from "../assets/img/clients_logo/Official_Logo_of_PCMC.jpeg";
import meter7 from "../assets/img/clients_logo/Puraniks.png";
import meter8 from "../assets/img/clients_logo/PWD.jpg";
import meter9 from "../assets/img/clients_logo/Tejas developers.jpg";
import meter10 from "../assets/img/clients_logo/Vilas Javdekar.jpg";
import meter11 from "../assets/img/clients_logo/Tejas.jpg";

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/vecteezy_grunge-modern-thumbnail-background_17749616.jpg"

export const Director = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="director" id="director">
        <div className="container">
            <div className="row p-5">
                <div className="col-12 director-text d-flex align-items-center justify-content-center">
                    <h2>Directors Mission and Vision</h2>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="row">
                <div className="col-12 d-flex align-items-center justify-content-center">
                    <div className="director-bx custom-container">
                            <div className="card ">
                                <img src={meter1} alt="Image" className="image"/>
                                <h2>Directors Name</h2>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br></br> Lorem Ipsum has been the industry's standard dummy text.</p>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
