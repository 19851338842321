import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component }  from 'react';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Clients } from "./components/Clients";
import { Projects } from "./components/Projects";
import { About } from "./components/About";
import { Director } from "./components/Director";
import { Footer } from "./components/Footer";
import { Quality } from "./components/Quality";
import { Contact } from "./components/Contact";
import { StickySocial } from "./components/StickySocial";

function App() {
  return (
    <div className="App">
      <StickySocial />
      <NavBar />
      <Banner />
      <About />
      <Projects />
      <Clients />
      <Director />
      <Quality />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;