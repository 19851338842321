import meter1 from "../assets/img/clients_logo/Anshul realty.jpg";
import meter2 from "../assets/img/clients_logo/Ashwini builders.png";
import meter3 from "../assets/img/clients_logo/Avanta.png";
import meter4 from "../assets/img/clients_logo/Avior-Logo1.png";
import meter5 from "../assets/img/clients_logo/Official_logo_of_the_Pune_Municpal_Corporation.jpeg";
import meter6 from "../assets/img/clients_logo/Official_Logo_of_PCMC.jpeg";
import meter7 from "../assets/img/clients_logo/Puraniks.png";
import meter8 from "../assets/img/clients_logo/PWD.jpg";
import meter9 from "../assets/img/clients_logo/Tejas developers.jpg";
import meter10 from "../assets/img/clients_logo/Vilas Javdekar.jpg";
import meter11 from "../assets/img/clients_logo/Tejas.jpg";

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/vecteezy_grunge-modern-thumbnail-background_17749616.jpg"

export const Clients = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const sliderImageUrl = [
    {
      src:meter1
    },
    {
        src:meter2
    },
    {
        src:meter3
    },
    {
        src:meter4
    },  
    {
        src:meter5
    },
    {
        src:meter6
    },
    {
        src:meter7
    },    
    {
        src:meter8
    },
    {
        src:meter9
    },   
    {
        src:meter10
    },   
    {
        src:meter11
    },       
  ];

  return (
    <section className="skill" id="clients">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>Clients</h2>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br></br> Lorem Ipsum has been the industry's standard dummy text.</p>
                        <Carousel
                            responsive={responsive}
                            autoPlay={true}
                            swipeable={true}
                            infinite={true}
                            partialVisible={false}
                            dotListClass="custom-dot-list-style"
                            className="owl-carousel owl-theme skill-slider">
                            {sliderImageUrl.map((imageUrl, index) => {
                            return (
                                <div className="slider item" key={index}>
                                <img src={imageUrl.src} alt="movie" />
                                </div>
                            );
                            })}
                        </Carousel>
                        {/* <Carousel responsive={responsive} infinite={true} autoPlay={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                                <img src={meter1} alt="Image" />
                                <h5>Web Development</h5>
                            </div>
                            <div className="item">
                                <img src={meter2} alt="Image" />
                                <h5>Brand Identity</h5>
                            </div>
                            <div className="item">
                                <img src={meter3} alt="Image" />
                                <h5>Logo Design</h5>
                            </div>
                            <div className="item">
                                <img src={meter4} alt="Image" />
                                <h5>Web Development</h5>
                            </div>
                            <div className="item">
                                <img src={meter5} alt="Image" />
                                <h5>Web Development</h5>
                            </div>
                            <div className="item">
                                <img src={meter6} alt="Image" />
                                <h5>Web Development</h5>
                            </div>
                            <div className="item">
                                <img src={meter7} alt="Image" />
                                <h5>Web Development</h5>
                            </div>
                            <div className="item">
                                <img src={meter8} alt="Image" />
                                <h5>Web Development</h5>
                            </div>
                            <div className="item">
                                <img src={meter9} alt="Image" />
                                <h5>Web Development</h5>
                            </div>
                            <div className="item">
                                <img src={meter10} alt="Image" />
                                <h5>Web Development</h5>
                            </div>
                            <div className="item">
                                <img src={meter11} alt="Image" />
                                <h5>Web Development</h5>
                            </div>
                        </Carousel> */}
                    </div>
                </div>
            </div>
        </div>
        <div className="background-image-left"/>
    </section>
  )
}
