import TrackVisibility from 'react-on-screen'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCity,  faPenToSquare, faThumbTack, faBriefcase, faBusinessTime, faLandmark, faBuildingShield, faHouse } from '@fortawesome/free-solid-svg-icons'
import CountUp from 'react-countup';
import Img from "../assets/img/pexels-vertical-bg.jpg"
import logo from "../assets/img/logo.jpeg";

export const About = () => {
  return (
    <div id="about">
          <div className="about-content">
            <div className="love-grid text-center">
              <div className="container">
                <div className="row p-3">
                  <div className="col-md-12">
                    <div className="main-title text-center wow fadeIn">
                    <div className="logo-image d-flex align-items-center justify-content-center">
                      {/* <TrackVisibility>
                        {({ isVisible }) =>
                          <div className={isVisible ? "animate__animated animate__zoomIn d-flex align-items-center justify-content-center" : " d-flex align-items-center justify-content-center"}>
                            <img src={logo} alt="Header Img"/>
                          </div>}
                      </TrackVisibility> */}
                      </div>
                      <h3>Our Vision</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>
                      N V CONCRETE is growing building solution company
                      that provides products of consistently high quality and
                      reliable service to customers and communities.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row love-row wow animate__animated animate__fadeInRightBig p-3">
                  <div className="col-md-3 col-sm-6">
                    <div className="love-details" data-wow-delay=".1s">
                      <FontAwesomeIcon icon={faCity}  className='love-icon'/>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>ONE STOP SOLUTION FOR CONCRETE</p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <div className="love-details" data-wow-delay=".3s">
                    <FontAwesomeIcon icon={faPenToSquare}  className='love-icon'/>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>OUR CREDIBILITY HAS BEEN PROVEN</p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <div className="love-details" data-wow-delay=".2s">
                    <FontAwesomeIcon icon={faThumbTack}  className='love-icon'/>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>AFFORDABLE PRICE, CERTIFIED FORWARDERS</p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <div className="love-details" data-wow-delay=".4s">
                    <FontAwesomeIcon icon={faBriefcase}  className='love-icon'/>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>SAFE AND TRUSTED COMPANY</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="me-grid">
              <div className="container">
                <div className="row">
                  <div className="col-md-4 col-sm-6 col-xs-12 about-col">
                    <div className="about-image">
                    <TrackVisibility>
                      {({ isVisible }) =>
                        <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                          <img src={Img} alt="Header Img"/>
                        </div>}
                    </TrackVisibility>
                    </div>
                  </div>
                  <div className="col-md-8 col-sm-6 col-xs-12 about-col">
                    <div className="about-details wow fadeIn">
                      <div className="main-title left-title text-left wow fadeIn">
                        <h3>WE ARE YOUR SOULUTION</h3>
                        <div className="underline1 no-margin"></div>
                        <div className="underline2 no-margin"></div>
                      </div>
                      <p className="wow fadeIn">
                        Established in the year 2018, N V CONCRETE has grown to
                        become one of the most reliable sources for world class
                        ready mix concrete in Bavdhan (Pune) delivering high
                        quality products to exact specification and on time to the
                        customers.
                        <br />
                        <br />
                        As we are already associated with B.S.Dhotre (Since 1980), have
                        abundant working experience in the stream of construction in every
                        area i.e., roads, highways, water works, earth works, buildings etc.
                        Our depth of knowledge and experience in this field have made us
                        come up with the best quality RMC at its best price.
                      </p>
                      <a className="about-link-2" href="#contact">
                        Contact US
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="work-counter text-center">
              <div className="counter">
                <div className="container">
                  <div className="row wow fadeIn">
                    <div className="col-md-3 col-sm-6">
                      <div className="work-statistics">
                        <FontAwesomeIcon icon={faBriefcase}  className='stat-icon'/>
                        <TrackVisibility>
                          {({ isVisible }) =>
                           <h3>
                           <CountUp delay={3} end={81} suffix={'+'} className="Count"/>
                           </h3>
                          }
                        </TrackVisibility>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>Clients</p>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <div className="work-statistics">
                        <FontAwesomeIcon icon={faBusinessTime}  className='stat-icon'/>
                        <TrackVisibility>
                          {({ isVisible }) =>
                           <h3>
                           <CountUp delay={3} end={100} suffix={'+'} className="Count"/>
                           </h3>
                          }
                        </TrackVisibility>                        
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>Projects</p>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <div className="work-statistics"> 
                        <FontAwesomeIcon icon={faHouse}  className='stat-icon'/>                       
                        <TrackVisibility>
                          {({ isVisible }) =>
                           <h3>
                           <CountUp delay={3} end={75} suffix={'+'} className="Count"/>
                           </h3>
                          }
                        </TrackVisibility>                         
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>Family</p>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <div className="work-statistics">
                      <FontAwesomeIcon icon={faBuildingShield}  className='stat-icon'/>         
                        <TrackVisibility>
                          {({ isVisible }) =>
                           <h3>
                           <CountUp delay={3} end={15} suffix={'+'} className="Count"/>
                           </h3>
                          }
                        </TrackVisibility>                              
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>Test</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  )
}