import { Container, Row, Col } from "react-bootstrap";
import TrackVisibility from 'react-on-screen'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { Link } from 'react-router-dom';

export const Contact = () => {
    return (
        <div className="contact" id="contact">
            <div className="love-grid text-center">
              <div className="container">
                <div className="row love-row wow animate__animated animate__fadeInRightBig p-3">
                  <h1 className="contact-text">Contact US </h1>
                  <div className="col-md-4 col-sm-6">
                    <div className="love-details" data-wow-delay=".1s">
                      <FontAwesomeIcon icon={faLocationDot}  className='love-icon'/>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>NV Concrete, Sr.No. 225/2, Bandal Estate,Uttamnagar, next to Puranik Abhitante, Bavdhan, Pune, Maharashtra 411021</p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="love-details" data-wow-delay=".3s">
                    <FontAwesomeIcon icon={faPhone}  className='love-icon'/>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>+91 93709 04315</p>
                      <p>+91 93709 04315</p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="love-details" data-wow-delay=".2s">
                    <FontAwesomeIcon icon={faEnvelope}  className='love-icon'/>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>vaibhav.patil@nvconcrete.in</p>
                    </div>
                  </div>
                </div>
                <div className="row love-row wow p-4">
                  <div className="col-md-12">
                   <TrackVisibility>
                      {({ isVisible }) =>
                        <div className={isVisible ? "animate__animated animate__zoomIn map-responsive" : "map-responsive"}>
                           <iframe 
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.085229079356!2d73.75481097519179!3d18.525050282569325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bf8fc566258d%3A0xbfedc36e2c1d49f3!2sNV%20Concrete!5e0!3m2!1sen!2sin!4v1692515635709!5m2!1sen!2sin" 
                            style={{ border: "0" }}
                            width="1200"
                            height="600"
                            allowfullscreen="" 
                            loading="lazy" 
                            referrerpolicy="no-referrer-when-downgrade">
                            </iframe>
                       </div>}
                    </TrackVisibility>
                  </div>
                </div>
              </div>
            </div>
        </div>
    )
}